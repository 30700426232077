<template>
  <div class="py-1 px-4">
    <div
      class="bg-v-ui-light-service-info-colored text-v-ui-text-service-info-colored h-8 bg-opacity-10 text-sm leading-4 flex items-center justify-center rounded"
    >
      Обращение закрыто
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportChatMessageCloseAppeal'
};
</script>

export const FILE_CATEGORIES = ['image', 'audio', 'video', 'document'];

export const FILE_TYPES = {
  ANALYSIS: {
    id: 1,
    title: 'consts.file-types.analysis'
  },
  RESEARCH: {
    id: 2,
    title: 'consts.file-types.research'
  },
  INSPECTION: {
    id: 3,
    title: 'consts.file-types.inspection'
  },
  OTHER: {
    id: 4,
    title: 'consts.file-types.other'
  }
};

export default async function({ $auth, route, redirect }) {
  const { token, access_token, refreshToken, ...query } = route.query;

  const externalToken = token ?? access_token;
  const externalRefreshToken = refreshToken;

  if (externalToken && externalRefreshToken) {
    if (!$auth.loggedIn) {
      try {
        await $auth.setUserToken(externalToken, externalRefreshToken);
        return redirect(route.path, query);
      } catch (e) {
        return redirect('/auth/');
      }
    } else {
      try {
        await $auth.logout();
        await $auth.setUserToken(externalToken, externalRefreshToken);
        return redirect(route.path, query);
      } catch (e) {
        return redirect('/auth/');
      }
    }
  }
}

<template>
  <support-chat-message-container :message="message">
    <div>
      <div v-if="name" class="text-sm leading-4 mb-0.5 font-medium">
        {{ name }}
      </div>
      <div class="pr-1.5 leading-5 w-full break-words" v-html="text"></div>
      <div class="flex items-center justify-end">
        <div class="text-xs leading-3 pt-[3px] text-v-ui-text-monochrome-27">
          {{ message.time }}
        </div>
        <client-only>
          <v-icon
            v-if="message.isRead"
            size="s"
            name="standard/check-read"
            class="stroke-v-ui-icon-service-info-colored ml-0.5"
          />
        </client-only>
      </div>
    </div>
  </support-chat-message-container>
</template>

<script>
import linkifyStr from 'linkifyjs/string';
import SupportChatMessageContainer from '@/components/domains/support/ui/chat/messages/SupportChatMessageContainer';

export default {
  name: 'SupportChatMessageText',
  components: {
    SupportChatMessageContainer
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    name() {
      return this.message?.name;
    },
    text() {
      return linkifyStr(this.message.text, {
        defaultProtocol: 'https',
        attributes: {
          class: 'text-v-ui-text-service-info-blue',
          target: '_blank'
        }
      }).replaceAll(/\n/g, '<br>');
    }
  }
};
</script>

import { PatientService, DoctorService, MetaService } from '@/services';
import ConsultationReviewService from '@/services/consultation-review/consultation-review.service';
import StoryService from '@/services/story/story.service';

export default function({ $axiosClient, $config, $authService }, inject) {
  const client = $axiosClient.instance;

  const metaService = new MetaService(client, $config);

  const patientService = new PatientService(client, $config);
  const doctorService = new DoctorService(client, $config, { metaService });
  const consultationReviewService = new ConsultationReviewService(
    client,
    $config
  );
  const storyService = new StoryService(client, $config, { $authService });

  inject('patientService', patientService);
  inject('doctorService', doctorService);
  inject('consultationReviewService', consultationReviewService);
  inject('storyService', storyService);
}

// import qs from 'querystring';
import qs from 'query-string';
import LabsApi from './labs.api';
import { createBlobFromArrayBuffer } from '~/services/files/files.helpers';
import { convertBlobToBase64 } from '~/helpers';

class LabsService {
  constructor(client, $config) {
    this.api = new LabsApi(client, $config);
  }

  async getCategoriesList() {
    const {
      data: { data }
    } = await this.api.categoriesList();

    return data;
  }

  async getServicesList({
    name,
    TagIds,
    IsPopular,
    branchId,
    PartnerIds,
    CityIds
  }) {
    const params = qs.stringify(
      {
        name,
        TagIds,
        IsPopular,
        PartnerIds,
        CityIds,
        branchIds: branchId
      },
      {
        skipNull: true
      }
    );
    const {
      data: { data }
    } = await this.api.list(params);

    return data;
  }

  async getBranchesList({
    CityId,
    AddressStr,
    Offset,
    ServiceIds,
    PartnerIds,
    MinCost,
    MaxCost
  }) {
    const params = qs.stringify(
      {
        CityId,
        AddressStr,
        PartnerIds,
        MinCost,
        MaxCost,
        Offset,
        ServiceIds
      },
      {
        skipNull: true
      }
    );

    const {
      data: { data }
    } = await this.api.branchesList(params);

    return data;
  }

  async getServiceById(id) {
    const params = qs.stringify(
      {
        id
      },
      {
        skipNull: true
      }
    );

    const {
      data: { data }
    } = await this.api.byId(params);

    return data;
  }

  async getTagById(id) {
    const params = qs.stringify(
      {
        id
      },
      {
        skipNull: true
      }
    );

    const {
      data: { data }
    } = await this.api.tagById(params);

    return data;
  }

  async costByBranch(params) {
    const {
      data: { data }
    } = await this.api.costByBranch(
      qs.stringify(params, {
        skipNull: true
      })
    );

    return data;
  }

  async getPartnersList() {
    const {
      data: { data }
    } = await this.api.partnersList();

    return data;
  }

  async getCitiesList() {
    const {
      data: { data }
    } = await this.api.citiesList();

    return data;
  }

  async getLabsCitiesList() {
    const {
      data: { data }
    } = await this.api.labsCitiesList();

    return data;
  }

  async createOrder(params) {
    const {
      data: { data }
    } = await this.api.createOrder(params);

    return data;
  }

  async getPartnerLogo(key) {
    const mimeType = key.split('.').slice(-1)[0];
    const { data } = await this.api.getPartnerLogo(key);
    const blob = createBlobFromArrayBuffer(data, mimeType);
    return convertBlobToBase64(blob);
  }

  async getOrder(id) {
    const {
      data: { data }
    } = await this.api.getOrder(id);
    return data;
  }

  async getServicesReferral(referralId) {
    const {
      data: { data }
    } = await this.api.getServicesReferral(referralId);
    return data;
  }
}

export default LabsService;

import {
  formatDuration,
  format,
  isFuture,
  differenceInDays,
  startOfDay
} from '@evd3v/date-fns';
import { ru } from '@evd3v/date-fns/locale';
import { convertBytesToMb } from '../files/files.helpers';
import { STATUS, TYPE_PROGRESS } from './referral-analysis.const';

export const getPatientInfo = (patient) => {
  const { firstName, lastName, middleName, gender, age } = patient;
  return {
    ...patient,
    name: `${lastName} ${firstName} ${middleName}`,
    gender: gender ? 'Женщина' : 'Мужчина',
    age: formatDuration({ years: age }, { locale: ru })
  };
};

export const getConsultationInfo = ({ doctor, consultationDate }) => {
  const { lastName, firstName, middleName } = doctor;

  const iFirst = firstName.slice(0, 1) || '';
  const iMiddle = middleName.slice(0, 1) || '';
  return {
    doctor: {
      ...doctor,
      name: `${lastName} ${firstName} ${middleName}`,
      initial: `${iFirst}${iMiddle}`
    },
    consultationDate: format(new Date(consultationDate), 'd MMM', {
      locale: ru
    })
  };
};

export const getBreadcrumbs = (status) => {
  const tabs = [{ title: 'Главная', url: '/' }];

  switch (status) {
    case STATUS.NEW:
      tabs.push({ title: 'Будущие события', url: '/events/future' });
      break;
    case STATUS.ACCEPTED:
      tabs.push({ title: 'Текущие события', url: '/events/current' });
      break;
    case STATUS.PROVIDED:
      tabs.push({ title: 'Закрытые события', url: '/events/closed' });
      break;
    case STATUS.DECLINED:
      tabs.push({ title: 'Будущие события', url: '/events/future' });
      break;
  }

  tabs.push({ title: 'Направления на анализы', url: '#' });
  return tabs;
};

export const getProgressInfo = ({ validUntil, createdAt, status }) => {
  validUntil = startOfDay(new Date(validUntil));
  createdAt = startOfDay(new Date(createdAt));
  const currentDate = startOfDay(new Date());
  const daysDifference = differenceInDays(validUntil, currentDate);
  const daysDifferenceCreatedToUntil = differenceInDays(validUntil, createdAt);
  const dayWord =
    daysDifference === 1
      ? 'день'
      : daysDifference > 1 && daysDifference < 5
      ? 'дня'
      : 'дней';

  const type = !isFuture(validUntil)
    ? TYPE_PROGRESS.PROVIDED
    : daysDifference <= 5
    ? TYPE_PROGRESS.ALMOST_OVER
    : '';

  return {
    available: isFuture(validUntil)
      ? `Доступно еще ${daysDifference} ${dayWord}`
      : 'Не доступно',
    validUntil: `до ${format(new Date(validUntil), 'd MMM', { locale: ru })}`,
    count: 100 - (daysDifference / daysDifferenceCreatedToUntil) * 100,
    type
  };
};

export const getResultFiles = ({ resultFiles }) => {
  return resultFiles?.map(
    ({ createdAt, fileExtension, fileSize, name, id }) => {
      const date = new Date(createdAt);
      return {
        id,
        name: `${name}.${fileExtension}`,
        extension: fileExtension.toUpperCase(),
        size: convertBytesToMb(fileSize),
        date: `${format(date, 'yy.MM.dd')} в ${format(date, 'HH:mm')}`
      };
    }
  );
};

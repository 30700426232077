import { MODALS } from '@/services/core/ui/const';
import { AUTH_STEPS } from '@/services/auth/auth.const';

const defaultState = {
  progressStep: AUTH_STEPS.PHONE_STEP,
  inProgress: false,
  phone: '+7',
  rawPhone: '',
  nextAttemptTime: null,
  isCodeInvalid: false,
  error: null
};

export const state = () => ({
  ...defaultState
});

export const actions = {
  setAuthorizationFlowState({ commit, dispatch, getters }, state) {
    if (this.$auth.loggedIn && getters.isFullAuthorized) {
      commit('RESET_STATE');
      return;
    }

    if (this.$auth.loggedIn && !getters.isFullAuthorized) {
      commit('SET_PROGRESS_STEP', AUTH_STEPS.USER_DATA_STEP);
    }

    commit('SET_IN_PROGRESS', state);

    if (this.$device.isIos && !this.$device.isSafari) {
      this.$uiService.showModal(MODALS.APPLICATION_DEVICE_NOT_SUPPORTED_DIALOG);
    } else {
      this.$uiService.showModal(MODALS.AUTH);
    }
  },
  toFirstStep({ commit }) {
    commit('SET_NEXT_ATTEMPT_TIME', null);
    commit('SET_PROGRESS_STEP', AUTH_STEPS.PHONE_STEP);
    commit('SET_IS_CODE_INVALID', false);
  },
  toSendCodeStep({ commit }, { rawPhone, phone, nextAttemptTime }) {
    commit('SET_PHONE', phone);
    commit('SET_RAW_PHONE', rawPhone);
    commit('SET_NEXT_ATTEMPT_TIME', nextAttemptTime);
    commit('SET_PROGRESS_STEP', AUTH_STEPS.SUBMIT_PHONE_STEP);
  },
  toUserDataStep({ commit }) {
    commit('SET_PROGRESS_STEP', AUTH_STEPS.USER_DATA_STEP);
  },
  setNextAttemptTime({ commit }, nextAttemptTime) {
    commit('SET_NEXT_ATTEMPT_TIME', nextAttemptTime);
  },
  setCodeValidationStatus({ commit }, value) {
    commit('SET_IS_CODE_INVALID', value);
  },
  setError({ commit }, error) {
    commit('SET_ERROR', error);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  }
};

export const mutations = {
  SET_IN_PROGRESS(state, inProgress) {
    state.inProgress = inProgress;
  },
  SET_RAW_PHONE(state, rawPhone) {
    state.rawPhone = rawPhone;
  },
  SET_PHONE(state, phone) {
    state.phone = phone;
  },
  SET_PROGRESS_STEP(state, step) {
    state.progressStep = step;
  },
  SET_NEXT_ATTEMPT_TIME(state, time) {
    state.nextAttemptTime = time;
  },
  SET_IS_CODE_INVALID(state, value) {
    state.isCodeInvalid = value;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState);
  }
};

export const getters = {
  nextAttemptTimeDate(state) {
    if (!state.nextAttemptTime) return null;
    const isDate = typeof state.nextAttemptTime.getMonth === 'function';

    if (isDate) {
      return state.nextAttemptTime;
    }

    return new Date(state.nextAttemptTime);
  },
  unfilledFields(state, getters, rootState) {
    if (!rootState.auth.loggedIn) {
      return [];
    }

    const { profile } = rootState.modules.patient;

    return profile.unfilledFields;
  },
  isFullAuthorized(state, getters, rootState) {
    if (!rootState.auth.loggedIn) {
      return false;
    }

    const { profile } = rootState.modules.patient;

    return profile?.unfilledFields?.length === 0;
  }
};

<template>
  <div
    class="text-v-ui-text-monochrome-55 text-sm leading-4 text-center px-6 py-3"
  >
    {{ message.text }}
  </div>
</template>

<script>
export default {
  name: 'SupportChatMessageSystem',
  props: {
    message: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
  <div
    class="flex items-end pl-4 pr-2.5 my-2 w-full"
    :class="[!isIncoming ? 'justify-end !pl-20 pr-2.5' : '!pr-20 pl-2.5']"
  >
    <u-avatar-support-chat
      v-if="isIncoming"
      :avatar="message.avatar"
      class="mr-2.5"
      :class="{ 'opacity-0': message.isLast }"
    />

    <div class="flex items-end max-w-full">
      <svg
        v-if="isIncoming"
        :class="{ 'opacity-0': message.isLast }"
        width="6"
        height="10"
        viewBox="0 0 6 10"
        class="fill-v-ui-light-service-info-monochrome min-w-[6px] min-h-[10px]"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_430_219)">
          <path
            d="M6 0C6 3.21429 4.23529 7.67857 0.705882 8.57143C0.705882 8.57143 0 8.57143 0 9.28572C0 10 0.705882 10 0.705882 10H6V0Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_219">
            <rect width="6" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div
        class="rounded-lg pt-2 pl-3 pr-1.5 pb-1 w-full"
        :class="[slotClass, message.isLast ? '!rounded-lg' : '']"
      >
        <slot />
      </div>

      <svg
        v-if="!isIncoming"
        :class="{ 'opacity-0': message.isLast }"
        width="6"
        height="10"
        viewBox="0 0 6 10"
        class="fill-v-ui-light-service-info-colored min-w-[6px] min-h-[10px]"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_430_217)">
          <path
            d="M0 0C0 3.21429 1.76471 7.67857 5.29412 8.57143C5.29412 8.57143 6 8.57143 6 9.28572C6 10 5.29412 10 5.29412 10H0V0Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_217">
            <rect width="6" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import UAvatarSupportChat from '@/components/ui/atoms/avatar/UAvatarSupportChat';

export default {
  name: 'SupportChatMessageContainer',
  components: {
    UAvatarSupportChat
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    slotClass() {
      return this.isIncoming
        ? 'bg-v-ui-light-service-info-monochrome rounded-bl-none'
        : 'bg-v-ui-light-service-info-colored rounded-br-none';
    },
    isIncoming() {
      return this.message.incoming;
    }
  }
};
</script>

<template>
  <div class="py-2.5">
    <div
      class="py-1 px-2 rounded-full bg-v-ui-overlay-monochrome-10 text-v-ui-text-monochrome-55 text-xs leading-3 mx-auto w-max"
    >
      {{ message.payload }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportChatMessageTimestamp',
  props: {
    message: {
      type: Object,
      required: true
    }
  }
};
</script>

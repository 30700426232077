<template>
  <div
    class="bg-v-ui-light-main w-9 h-9 min-w-[36px] rounded-full flex items-center justify-center overflow-hidden"
  >
    <img v-if="avatar" :src="avatar" class="w-9 h-9" />
    <v-icon v-else name="s/support" class="stroke-v-ui-icon-main-alt" />
  </div>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem';

export default {
  name: 'UAvatarSupportChat',
  components: {
    VIcon
  },
  props: {
    avatar: {
      type: String,
      default: ''
    }
  }
};
</script>

import { format } from '@evd3v/date-fns';

export const convertBytesToMb = (b) => {
  const kb = b / 1000;
  const mb = kb / 1000;
  if (b < 1000) return `${b} B`;
  if (kb < 1000) return `${kb.toFixed(2)} KB`;
  return `${mb.toFixed(2)} MB`;
};

export const convertBlobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].includes('base64')
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const downloadFile = (file, name) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);

  link.download = name; // generateTimestampFileName(name ?? 'file');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
  }, 100);
};

export const generateTimestampFileName = (prefix) => {
  return `${prefix}-${format(new Date(), 'dd-MM-yyyy_HH-mm')}`;
};

export const createBlobFromArrayBuffer = (buffer, mimeType) => {
  return new Blob([buffer], { type: mimeType });
};

export const arrayBufferToObject = (buffer) => {
  return JSON.parse(new TextDecoder().decode(buffer));
};

export const getExtension = (type) => {
  return type?.split('/')[1].toUpperCase();
};

import { render, staticRenderFns } from "./UDropdown.vue?vue&type=template&id=4603be62&scoped=true&"
import script from "./UDropdown.vue?vue&type=script&lang=js&"
export * from "./UDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4603be62",
  null
  
)

export default component.exports
<template>
  <div class="overflow-auto w-full">
    <div class="flex items-end flex-col w-full min-h-full justify-end">
      <div
        v-for="(messages, key) of groupsMessages"
        :key="key"
        class="messages-group w-full"
      >
        <component
          :is="`SupportChatMessage${message.messageType}`"
          v-for="message of messages"
          :key="message.key"
          :message="message"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SupportChatMessageFile from '@/components/domains/support/ui/chat/messages/SupportChatMessageFile';
import SupportChatMessageImage from '@/components/domains/support/ui/chat/messages/SupportChatMessageImage';
import SupportChatMessageText from '@/components/domains/support/ui/chat/messages/SupportChatMessageText';
import SupportChatMessageSystem from '@/components/domains/support/ui/chat/messages/SupportChatMessageSystem';
import SupportChatMessageTimestamp from '@/components/domains/support/ui/chat/messages/SupportChatMessageTimestamp';
import SupportChatMessageCloseAppeal from '@/components/domains/support/ui/chat/messages/SupportChatMessageCloseAppeal';

export default {
  name: 'SupportChatMessages',
  components: {
    SupportChatMessageFile,
    SupportChatMessageImage,
    SupportChatMessageText,
    SupportChatMessageSystem,
    SupportChatMessageTimestamp,
    SupportChatMessageCloseAppeal
  },
  props: {
    groupsMessages: {
      type: Array,
      default: () => []
    }
  }
};
</script>

import { Api } from '~/api';

class LabsApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
  }

  list(params) {
    return this.get('/laboratory/service/list', {
      params,
      paramsSerializer: (params) => params
    });
  }

  byId(params) {
    return this.get('/laboratory/service/get', {
      params,
      paramsSerializer: (params) => params
    });
  }

  costByBranch(params) {
    return this.get('/laboratory/service/cost', {
      params,
      paramsSerializer: (params) => params
    });
  }

  getServicesReferral(referralId) {
    return this.get('/laboratory/service/cost/referral', {
      params: { referralId }
    });
  }

  categoriesList() {
    return this.get('/laboratory/tag/list?TagType=1');
  }

  branchesList(params) {
    return this.get('/laboratory/branch/list', {
      params,
      paramsSerializer: (params) => params
    });
  }

  tagById(params) {
    return this.get('/laboratory/tag/get', {
      params,
      paramsSerializer: (params) => params
    });
  }

  partnersList() {
    return this.get('/laboratory/partner/list');
  }

  citiesList() {
    return this.get('/city/list');
  }

  labsCitiesList() {
    return this.get('/city/laboratory-list');
  }

  createOrder(params) {
    return this.post('/laboratory/order/create', params);
  }

  getPartnerLogo(key) {
    return this.get('/laboratory/partner/logo', {
      responseType: 'arraybuffer',
      params: {
        key
      }
    });
  }

  getOrder(orderId) {
    return this.get('/laboratory/order/get', {
      params: { orderId }
    });
  }
}

export default LabsApi;

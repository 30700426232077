const middleware = {}

middleware['dynamic-authorization'] = require('../middleware/dynamic-authorization.js')
middleware['dynamic-authorization'] = middleware['dynamic-authorization'].default || middleware['dynamic-authorization']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['userData'] = require('../middleware/userData.js')
middleware['userData'] = middleware['userData'].default || middleware['userData']

export default middleware

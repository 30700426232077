import StoryApi from '@/services/story/story.api';

class StoryService {
  constructor(client, $config, deps) {
    this.api = new StoryApi(client, $config);
    this.$config = $config;
    this.$authService = deps.$authService;
  }

  async listActive() {
    const { stories, totalNumberOfStories } = await this.api.listActive();
    return {
      stories: this.coverImageMapper(stories, 'coverImageDownloadUrl'),
      totalNumberOfStories
    };
  }

  async list() {
    const stories = await this.api.list();
    return this.coverImageMapper(stories, 'coverImageDownloadUrl');
  }

  async carouselByStoryId(storyId) {
    const storyCarousel = await this.api.carouselByStoryId(storyId);
    return {
      ...storyCarousel,
      carousel: this.coverImageMapper(
        storyCarousel.carousel,
        'contentFileDownloadUrl',
        'source'
      ).map((carousel) => ({
        ...carousel,
        sourceType: carousel.contentFileContentType.split('/')[0]
      }))
    };
  }

  addToFavourite(storyId) {
    return this.api.addToFavourite(storyId);
  }

  removeFromFavourite(storyId) {
    return this.api.removeFromFavourite(storyId);
  }

  markCarouselItemAsViewed(carouselItemId) {
    if (this.$authService.loggedIn) {
      return this.api.markCarouselItemAsViewed(carouselItemId);
    }
  }

  getImageCoverUrl(storyImageUrl) {
    return this.$authService.createImageUrl(storyImageUrl);
  }

  coverImageMapper(stories, key, newKey = 'image') {
    return stories.map((story) => ({
      ...story,
      [newKey]: this.getImageCoverUrl(story[key])
    }));
  }

  get isAddToFavouriteEnabled() {
    return this.$authService.loggedIn;
  }
}

export default StoryService;

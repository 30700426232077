<template>
  <div class="bg-v-ui-root-monochrome-5 pb-6 min-h-screen">
    <vc-application-notification />
    <u-navbar :logged-in="$auth.loggedIn" />
    <nuxt />

    <client-only>
      <SystemModals />
    </client-only>

    <common-modal-container />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import VcApplicationNotification from '../components/domains/application/VcApplicationNotification.vue';
import SystemModals from '../components/modal/SystemModals.vue';
import CommonModalContainer from '../components/common/CommonModalContainer.vue';
import UNavbar from '@/components/ui/organisms/navbar/UNavbar';

export default {
  name: 'MainLayout',
  components: {
    CommonModalContainer,
    SystemModals,
    VcApplicationNotification,
    UNavbar
  },
  mounted() {
    this.setIsMobile();
    if (this.$auth.loggedIn) {
      this.$statusService.subscribe();
    }
    window.addEventListener('resize', this.setIsMobile);
  },
  methods: {
    ...mapMutations(['setIsMobile'])
  }
};
</script>

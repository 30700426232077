export const MODALS = {
  COMMON_ERROR_DIALOG: {
    id: 'COMMON_ERROR_DIALOG',
    component: () => import('@/components/common/CommonErrorDialog')
  },
  REQUEST_ERROR_DIALOG: {
    id: 'REQUEST_ERROR_DIALOG',
    component: () => import('@/components/domains/error/RequestErrorDialog')
  },
  AUTH: {
    id: 'AUTH',
    component: () => import('@/components/domains/auth/VcAuthFlowModal')
  },
  AUTH_LOGOUT_DIALOG: {
    id: 'AUTH_LOGOUT_DIALOG',
    component: () => import('@/components/domains/auth/AuthLogoutDialog')
  },
  AUTH_FAILED_DIALOG: {
    id: 'AUTH_FAILED_DIALOG',
    component: () => import('@/components/domains/auth/AuthFailedDialog')
  },
  APPLICATION_DEVICE_NOT_SUPPORTED_DIALOG: {
    id: 'APPLICATION_DEVICE_NOT_SUPPORTED_DIALOG',
    component: () =>
      import(
        '@/components/domains/application/VcApplicationDeviceNotSupportedDialog'
      )
  },
  APPLICATION_SETTINGS_MODAL: {
    id: 'APPLICATION_SETTINGS_MODAL',
    component: () =>
      import(
        '@/components/domains/application/settings/ApplicationSettingsModal'
      )
  },

  DUTY_CONSULTATION_SELECT: {
    id: 'DUTY_CONSULTATION_SELECT',
    component: () =>
      import('@/components/domains/consultation/VcDutyConsultationSelectModal')
  },
  DUTY_CONSULTATION_CREATE: {
    id: 'DUTY_CONSULTATION_CREATE',
    component: () =>
      import('@/components/domains/consultation/VcDutyConsultationCreateModal')
  },
  DUTY_CONSULTATION_CONFIRM: {
    id: 'DUTY_CONSULTATION_CONFIRM',
    component: () =>
      import('@/components/domains/consultation/VcDutyConsultationConfirmModal')
  },
  DUTY_CONSULTATION_CREATE_ERROR: {
    id: 'DUTY_CONSULTATION_CREATE_ERROR',
    component: () =>
      import(
        '@/components/domains/consultation/VcDutyConsultationCreateErrorDialog'
      )
  },

  CONSULTATION_REVIEW_MODAL: {
    id: 'CONSULTATION_REVIEW_MODAL',
    component: () =>
      import('@/components/domains/consultation/ConsultationReviewModal')
  },
  CONSULTATION_CANCEL_CONFIRM_DIALOG: {
    id: 'CONSULTATION_CANCEL_CONFIRM_DIALOG',
    component: () =>
      import(
        '@/components/domains/consultation/ConsultationCancelConfirmDialog'
      )
  },
  CONSULTATION_NOT_PAID_DIALOG: {
    id: 'CONSULTATION_NOT_PAID_DIALOG',
    component: () =>
      import('@/components/domains/consultation/ConsultationNotPaidDialog')
  },
  CONSULTATION_CREATE: {
    id: 'CONSULTATION_CREATE',
    component: () =>
      import('@/components/domains/consultation/VcConsultationCreateModal')
  },
  CONSULTATION_CREATE_CONFIRM: {
    id: 'CONSULTATION_CREATE_CONFIRM',
    component: () =>
      import(
        '@/components/domains/consultation/VcConsultationCreateDialogModal'
      )
  },
  CONSULTATION_RECORD_SELF_MODAL: {
    id: 'CONSULTATION_RECORD_SELF_MODAL',
    component: () =>
      import('@/components/domains/consultation/ConsultationRecordSelfModal')
  },
  CONSULTATION_CREATE_ERROR_ADULT_DIALOG: {
    id: 'CONSULTATION_CREATE_ERROR_ADULT_DIALOG',
    component: () =>
      import(
        '@/components/domains/consultation/VcConsultationCreateErrorAdultDialog'
      )
  },
  CONSULTATION_CREATE_ERROR_CHILD_DIALOG: {
    id: 'CONSULTATION_CREATE_ERROR_CHILD_DIALOG',
    component: () =>
      import(
        '@/components/domains/consultation/VcConsultationCreateErrorChildDialog'
      )
  },

  DOCTOR_CARD_ABOUT_WORK_MODAL: {
    id: 'DOCTOR_CARD_ABOUT_WORK_MODAL',
    component: () =>
      import('@/components/domains/doctor/card/ui/DoctorCardAboutWorkModal')
  },

  INSURANCE_POLICY_PROGRAM_DESCRIPTION_MODAL: {
    id: 'INSURANCE_POLICY_PROGRAM_DESCRIPTION_MODAL',
    component: () =>
      import(
        '@/components/domains/insurance/VcInsurancePolicyProgramDescriptionModal'
      )
  },

  DOCTOR_CLINIC_MODAL: {
    id: 'DOCTOR_CLINIC_MODAL',
    component: () => import('@/components/domains/doctor/DoctorClinicModal')
  },

  CONSULTATION_STATUS_UPDATE_EVENT_DIALOG: {
    id: 'CONSULTATION_STATUS_UPDATE_EVENT_DIALOG',
    component: () =>
      import(
        '@/components/domains/consultation/VcConsultationStatusUpdateEventDialog'
      )
  },
  CURRENT_TASK_STATUS_MODAL: {
    id: 'CURRENT_TASK_STATUS_MODAL',
    component: () =>
      import('@/components/domains/monitoring/VcCurrentTaskStatusModal')
  },

  REFERRAL_STATUS_UPDATE_EVENT_DIALOG: {
    id: 'REFERRAL_STATUS_UPDATE_EVENT_DIALOG',
    component: () =>
      import(
        '@/components/domains/referral-analysis/VcReferralStatusUpdateEventDialog'
      )
  },
  REFERRAL_DOWNLOAD_ANALYSIS_MODAL: {
    id: 'REFERRAL_DOWNLOAD_ANALYSIS_MODAL',
    component: () =>
      import('@/components/domains/referral-analysis/ui/DownloadAnalysis')
  },
  REFERRAL_DECLINE_DIALOG: {
    id: 'REFERRAL_DECLINE_DIALOG',
    component: () =>
      import('@/components/domains/referral-analysis/VcReferralDeclineDialog')
  },
  REFERRAL_CLOSE_DIALOG: {
    id: 'REFERRAL_CLOSE_DIALOG',
    component: () =>
      import('@/components/domains/referral-analysis/VcReferralCloseDialog')
  },

  LABS_BRANCH_FILTER_MODAL: {
    id: 'LABS_BRANCH_FILTER_MODAL',
    component: () =>
      import('@/components/domains/labs/LabsBranchFilterModal.vue')
  },

  /** DOCTORS PAGE */
  DOCTORS_CHOSE_LIST_CHECKBOX_MODAL: {
    id: 'DOCTORS_CHOSE_LIST_CHECKBOX_MODAL',
    component: () =>
      import(
        '@/components/domains/doctors/modal/DoctorsChoseListCheckboxModal.vue'
      )
  },
  DOCTORS_SELECTED_FILTERS_MODAL: {
    id: 'DOCTORS_SELECTED_FILTERS_MODAL',
    component: () =>
      import(
        '@/components/domains/doctors/modal/DoctorsSelectedFiltersModal.vue'
      )
  },
  DOCTORS_FILTERS_MODAL: {
    id: 'DOCTORS_FILTERS_MODAL',
    component: () =>
      import('@/components/domains/doctors/modal/DoctorsFiltersModal.vue')
  },
  DOCTORS_SCHEDULE_MODAL: {
    id: 'DOCTORS_SCHEDULE_MODAL',
    component: () =>
      import('@/components/domains/doctors/modal/DoctorScheduleModal.vue')
  },
  /** ./DOCTORS PAGE */

  STORY_ALL_MODAL: {
    id: 'STORY_ALL_MODAL',
    component: () =>
      import('@/components/domains/story/DomainAllStoryModal.vue')
  },
  STORY_VIEWER: {
    id: 'STORY_VIEWER',
    component: () => import('@/components/domains/story/DomainStoryViewer.vue')
  }
};

import { STATUS, INFO_MESSAGE } from './referral-analysis.const';
export const isTabs = ({ status, order }) => {
  return status === (STATUS.ACCEPTED && order) || (STATUS.PROVIDED && order);
};

export const isNew = ({ status }) => {
  return status === STATUS.NEW;
};

export const isAccept = ({ status }) => {
  return status === STATUS.ACCEPTED;
};

export const isProvided = ({ status }) => {
  return status === STATUS.PROVIDED;
};

export const isDeclined = ({ status }) => {
  return status === STATUS.DECLINED;
};

export const getTypeInfoMessage = ({ status, order }) => {
  switch (status) {
    case STATUS.ACCEPTED:
      if (order) {
        return INFO_MESSAGE.takeTest;
      } else {
        return INFO_MESSAGE.downloadResults;
      }
    case STATUS.PROVIDED:
      return INFO_MESSAGE.passedFullTests;
    case STATUS.DECLINED:
      return INFO_MESSAGE.recommendationCancel;
  }
};

export const isVisibleFiles = ({ status, order }) => {
  return (status === STATUS.ACCEPTED && order) || status === STATUS.PROVIDED;
};

export const isVisibleProgress = ({ status, validUntil }) => {
  switch (status) {
    case STATUS.NEW:
      return true;
    case STATUS.ACCEPTED:
      return true;
    case STATUS.DECLINED:
      if (new Date(validUntil) > new Date()) return true;
  }

  return false;
};

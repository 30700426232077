export const formatedListsFromFilters = (list) => {
  return list
    .filter((i) => i.isAvailable)
    .map((i) => {
      return {
        id: i.key,
        label: i.data?.title,
        value: i.isSelected,
        weight: i.data?.weight || 0
      };
    })
    .sort((a, b) => b.weight - a.weight);
};

export const addTypeInList = (list, key) => {
  return list.map((i) => {
    return {
      ...i,
      type: key
    };
  });
};

export const initStickyScroll = () => {
  const a = document.querySelector('#filters');
  let b = null;
  let K = null;
  let Z = 0;
  const P = 100;
  const N = 0;
  window.addEventListener('scroll', Ascroll, false);
  document.body.addEventListener('scroll', Ascroll, false);
  function Ascroll() {
    const Ra = a.getBoundingClientRect();
    const R1bottom = document.querySelector('#doctors').getBoundingClientRect()
      .bottom;

    if (Ra.bottom < R1bottom) {
      if (b == null) {
        const Sa = getComputedStyle(a, '');
        let s = '';
        for (let i = 0; i < Sa.length; i++) {
          if (
            Sa[i].indexOf('overflow') === 0 ||
            Sa[i].indexOf('padding') === 0 ||
            Sa[i].indexOf('border') === 0 ||
            Sa[i].indexOf('outline') === 0 ||
            Sa[i].indexOf('box-shadow') === 0 ||
            Sa[i].indexOf('background') === 0
          ) {
            s += Sa[i] + ': ' + Sa.getPropertyValue(Sa[i]) + '; ';
          }
        }
        b = document.createElement('div');
        b.className = 'doc-stop';
        b.style.cssText =
          s + ' box-sizing: border-box; width: ' + a.offsetWidth + 'px;';
        a.insertBefore(b, a.firstChild);
        const l = a.childNodes.length;
        for (let i = 1; i < l; i++) {
          b.appendChild(a.childNodes[1]);
        }
        a.style.height = b.getBoundingClientRect().height + 'px';
        a.style.padding = '0';
        a.style.border = '0';
      }
      const Rb = b.getBoundingClientRect();
      const Rh = Ra.top + Rb.height;
      const W = document.documentElement.clientHeight;
      const R1 = Math.round(Rh - R1bottom);
      const R2 = Math.round(Rh - W);
      if (Rb.height > W) {
        if (Ra.top < K) {
          // скролл вниз
          if (R2 + N > R1) {
            // не дойти до низа
            if (Rb.bottom - W + N <= 0) {
              // подцепиться
              b.className = 'doc-sticky';
              b.style.top = W - Rb.height - N + 'px';
              Z = N + Ra.top + Rb.height - W;
            } else {
              b.className = 'doc-stop';
              b.style.top = -Z + 'px';
            }
          } else {
            b.className = 'doc-stop';
            b.style.top = -R1 + 'px';
            Z = R1;
          }
        } else {
          // скролл вверх
          // eslint-disable-next-line no-lonely-if
          if (Ra.top - P < 0) {
            // не дойти до верха
            if (Rb.top - P >= 0) {
              // подцепиться
              b.className = 'doc-sticky';
              b.style.top = P + 'px';
              Z = Ra.top - P;
            } else {
              b.className = 'doc-stop';
              b.style.top = -Z + 'px';
            }
          } else {
            b.className = '';
            b.style.top = '';
            Z = 0;
          }
        }
        K = Ra.top;
      } else if (Ra.top - P <= 0) {
        if (Ra.top - P <= R1) {
          b.className = 'doc-stop';
          b.style.top = -R1 + 'px';
        } else {
          b.className = 'doc-sticky';
          b.style.top = P + 'px';
        }
      } else {
        b.className = '';
        b.style.top = '';
      }
      window.addEventListener(
        'resize',
        function() {
          a.children[0].style.width = getComputedStyle(a, '').width;
        },
        false
      );
    }
  }
};

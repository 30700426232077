<template>
  <div
    id="support-chat-input"
    class="flex items-center w-full px-4 h-12 min-h-12 border-t border-v-ui-line-monochrome-10 overflow-hidden"
  >
    <div class="flex items-center w-6 h-6 relative cursor-pointer mr-2">
      <input
        ref="fileInput"
        class="opacity-0 relative w-6 h-6 cursor-pointer"
        type="file"
        multiple
        accept="application"
        @change="onFileUpload"
      />
      <v-icon
        name="m/attach"
        class="stroke-v-ui-icon-monochrome-27 pointer-events-none w-6 h-6 z-50 absolute top-0 left-0"
      />
    </div>
    <u-resizable-textarea
      ref="resize"
      class="!border-none h-12"
      placeholder="Сообщение"
      @messageSend="onMessageSend"
      @inputText="getMessageText"
    />
    <div class="flex items-center w-6 h-6 ml-2 cursor-pointer">
      <v-icon
        name="m/send-fill"
        class="fill-v-ui-icon-monochrome-27"
        :class="{ '!fill-v-ui-icon-main': text }"
        @click="onMessageSend"
      />
    </div>
  </div>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem';
import UResizableTextarea from '@/components/ui/atoms/textarea/UResizableTextarea';
import { MESSAGE_TYPE } from '@/services/support-chat/support.const';

export default {
  name: 'SupportChat',
  components: {
    VIcon,
    UResizableTextarea
  },
  data() {
    return {
      text: ''
    };
  },
  methods: {
    getMessageText(text) {
      this.text = text;
    },
    async onFileUpload(files) {
      await this.$supportChatService.sendFiles([...files.target.files]);
      this.$refs.fileInput.value = null;
    },
    async onMessageSend() {
      if (this.text) {
        await this.$supportChatService.sendMessage({
          text: this.text,
          messageType: MESSAGE_TYPE.TEXT,
          payload: null
        });

        this.$refs.resize.$refs.textarea.textContent = '';
        this.text = '';
      }
    }
  }
};
</script>

<style lang="scss">
input::-webkit-file-upload-button {
  cursor: pointer;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"unavbar",staticClass:"sticky top-0 h-14 z-50 bg-v-ui-root-monochrome-0 lg:h-[72px] border-b-2 border-v-ui-icon-monochrome-0"},[_c('div',{staticClass:"container h-full flex justify-between items-center"},[_c('div',{staticClass:"h-7 lg:h-9 lg:mb-4"},[_c('u-link',{staticClass:"block lg:w-40",attrs:{"link":{ path: '/' }},on:{"click":_vm.closeMenu}},[_c('nuxt-img',{staticClass:"w-full h-full",attrs:{"src":_vm.logoPath,"loading":"lazy","format":"webp","sizes":"lg:120 xl:160","quality":"100","alt":"logo","fit":"fill"}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLinksVisible),expression:"isLinksVisible"}],staticClass:"fixed z-50 inset-0 mt-14 bg-v-ui-root-monochrome-0 lg:!block lg:static lg:mt-0 lg:flex-1"},[_c('ul',{staticClass:"flex flex-col h-full lg:flex-row lg:items-center lg:container",on:{"click":_vm.onClickMenu}},[_vm._l((_vm.links),function(link){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(link.enabled),expression:"link.enabled"}],key:link.title,staticClass:"relative border-l-4 border-l-v-ui-root-monochrome-0 px-3 lg:border-none lg:ml-4 lg:py-2.5 lg:px-4 lg:bg-v-ui-root-monochrome-0",class:{
            'bg-[#EFEBFD] !border-l-v-ui-root-main':
              link.inner && link.link.name === _vm.currentRouteName
          }},[(link.dropdown)?_c('u-dropdown',{attrs:{"label":link.title,"btn-type":"ghost","items":link.items,"button-class":"text-xl font-medium md:text-base md:px-3 !px-0 md:py-0 py-2.5"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('u-link',{staticClass:"flex items-center relative h-9 border-l-4 border-l-v-ui-root-monochrome-0 -left-4 px-3 text-base text-v-ui-text-monochrome-55 font-medium lg:border-none lg:text-v-ui-root-main lg:bg-v-ui-root-monochrome-0 lg:left-0 lg:px-0",class:{
                  'bg-[#EFEBFD] !border-l-v-ui-root-main !text-v-ui-root-main':
                    item.inner && item.link.name === _vm.currentRouteName
                },attrs:{"link":_vm.createLink(item),"inner":item.inner}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])]}}],null,true)}):_c('u-link',{ref:link.ref,refInFor:true,staticClass:"h-14 flex items-center justify-between w-full text-v-ui-root-main text-xl font-medium lg:h-auto lg:text-base",attrs:{"inner":link.inner,"link":_vm.createLink(link)}},[_c('span',[_vm._v(" "+_vm._s(link.title))])])],1)}),_vm._v(" "),_c('li',{staticClass:"ml-auto mt-auto lg:mt-0 w-full lg:w-auto"},[(_vm.loggedIn)?_c('div',{staticClass:"relative"},[_c('u-dropdown',{staticClass:"hidden lg:block",attrs:{"label":_vm.$auth.user.firstName,"items":_vm.loggedInLinks},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(!item.isButton)?_c('u-link',{staticClass:"flex items-center text-sm font-medium text-v-ui-root-main h-9",attrs:{"link":_vm.createLink(item),"inner":item.inner}},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")]):_c('span',{staticClass:"flex items-center text-sm font-medium text-v-ui-root-main h-9 cursor-pointer",on:{"click":item.handler}},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")])]}}],null,false,2131002260)}),_vm._v(" "),_c('div',{staticClass:"flex flex-col pb-6 bg-v-ui-root-monochrome-5 lg:hidden"},[(_vm.isFullAuthorized)?_c('p',{staticClass:"flex items-center pl-4 text-xl font-medium h-14"},[_vm._v("\n                "+_vm._s(_vm.$auth.user.firstName)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.loggedInLinks),function(item,idx){return [(!item.isButton)?_c('u-link',{key:idx,staticClass:"flex items-center h-11 pl-3 border-l-4 border-l-v-ui-root-monochrome-5 text-base text-v-ui-text-monochrome-55 font-medium lg:text-v-ui-root-main lg:border-none",class:{
                    'bg-[#EFEBFD] !border-l-v-ui-root-main !text-v-ui-root-main':
                      item.link.name === _vm.currentRouteName
                  },attrs:{"link":_vm.createLink(item),"inner":item.inner}},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")]):_c('span',{key:idx,staticClass:"flex items-center h-11 pl-3 border-l-4 border-l-v-ui-root-monochrome-5 text-base text-v-ui-text-monochrome-55 font-medium cursor-pointer lg:text-v-ui-root-main lg:border-none",on:{"click":item.handler}},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")])]})],2)],1):_c('div',{staticClass:"md:px-0 px-4 md:pb-0 pb-16"},[_c('v-button',{staticClass:"w-full",attrs:{"type":"light","rounded":"","size":"m","label":_vm.$t('common.buttons.enter')},on:{"click":_vm.toAuth}})],1)])],2)]),_vm._v(" "),_c('a',{staticClass:"hidden whitespace-nowrap ml-5 text-v-ui-text-monochrome text-xl font-medium lg:block",attrs:{"href":"tel:88005000299"}},[_vm._v("\n      8 800 500 0299\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[(_vm.isChatAppealEnabled)?_c('div',{staticClass:"relative ml-7"},[_c('div',{staticClass:"relative cursor-pointer",on:{"click":_vm.toggleSupport}},[_c('v-icon',{staticClass:"stroke-v-ui-root-main",attrs:{"name":"m/dialog"}}),_vm._v(" "),(_vm.isNewMessageSupportChat && !_vm.isSupport)?_c('div',{staticClass:"absolute top-[-4px] right-[-4px] w-3 h-3 bg-v-ui-root-accent border-[3px] border-v-ui-icon-monochrome-0 rounded-full"}):_vm._e()],1),_vm._v(" "),(_vm.isSupport)?_c('VcSupport',{on:{"close":_vm.toggleSupport}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-icon',{staticClass:"ml-4 text-v-ui-icon-main lg:hidden",attrs:{"name":_vm.isLinksVisible ? 'm/cross' : 'm/menu'},nativeOn:{"click":function($event){return _vm.toggleMenu.apply(null, arguments)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="relative z-50">
    <v-modal-container :modals="modals" @close="closeModal" />
    <v-toast-container :toasts="toasts" @close="hideToast" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  VModalContainer,
  VToastContainer
} from '@doctis.front/doctis.designsystem';

export default {
  name: 'CommonModalContainer',
  components: { VModalContainer, VToastContainer },
  computed: {
    ...mapState('modules/ui', ['modals', 'toasts'])
  },
  methods: {
    closeModal(modal) {
      this.$uiService.closeModalById(modal.id);
    },
    hideToast(toast) {
      this.$uiService.hideToastById(toast.id);
    }
  }
};
</script>

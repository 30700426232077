<template>
  <support-chat-message-container :message="message">
    <div>
      <div class="flex items-center">
        <div
          class="min-w-[48px] w-12 h-12 rounded-lg flex items-center justify-center mr-2 cursor-pointer bg-opacity-10"
          :class="[
            isIncoming
              ? 'bg-v-ui-root-service-info-monochrome'
              : 'bg-v-ui-root-service-info-colored'
          ]"
          @click="downloadFile"
        >
          <v-icon
            name="m/download"
            :class="[
              isIncoming
                ? 'stroke-v-ui-icon-monochrome'
                : 'stroke-v-ui-icon-service-info-colored'
            ]"
          />
        </div>
        <div class="w-[calc(100%-60px)]">
          <div
            class="mb-1 text-sm leading-4 font-medium overflow-hidden overflow-ellipsis line-clamp-2 break-words"
          >
            {{ message.title }}
          </div>
          <div class="text-v-ui-text-monochrome-27 text-xs leading-3">
            {{ message.size }} {{ message.extension }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end">
        <div class="text-xs leading-3 pt-[3px] text-v-ui-text-monochrome-27">
          {{ message.time }}
        </div>
        <v-icon
          v-if="message.isRead"
          size="s"
          name="standard/check-read"
          class="stroke-v-ui-icon-service-info-colored ml-0.5"
        />
      </div>
    </div>
  </support-chat-message-container>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem';
import { downloadFile } from '@/services/files/files.helpers';
import SupportChatMessageContainer from '@/components/domains/support/ui/chat/messages/SupportChatMessageContainer';

export default {
  name: 'SupportChatMessageFile',
  components: {
    VIcon,
    SupportChatMessageContainer
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    isIncoming() {
      return this.message.incoming;
    },
    name() {
      return this.message?.name;
    }
  },
  methods: {
    downloadFile() {
      const { blob, title } = this.message;
      downloadFile(blob, title);
    }
  }
};
</script>

<template>
  <div class="bg-v-ui-background-grey overflow-hidden">
    <u-navbar :logged-in="$auth.loggedIn" />
    <nuxt />
    <!-- <u-footer v-if="!isMobileDevice" /> -->

    <client-only>
      <notifications
        position="bottom right"
        group="basicNotifications"
        width="352"
        :max="1"
        classes="doctis-notification"
        animation-type="velocity"
        :animation="animation"
      >
        <template slot="body" slot-scope="{ item, close }">
          <div class="doctis-notification">
            <div class="flex justify-between items-center">
              <p class="notification-title">
                {{ item.title }}
              </p>
              <div class="self-start" @click="close">
                <v-icon class="text-[#A9A9A9]" name="m/cross" />
              </div>
            </div>
            <p class="notification-content">
              {{ item.text }}
            </p>
          </div>
        </template>
      </notifications>
    </client-only>

    <client-only v-if="$auth.loggedIn">
      <SystemModals />
    </client-only>

    <common-modal-container />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { VIcon } from '@doctis.front/doctis.designsystem';
import CommonModalContainer from '../components/common/CommonModalContainer.vue';
import UNavbar from '@/components/ui/organisms/navbar/UNavbar';
import SystemModals from '@/components/modal/SystemModals.vue';

export default {
  name: 'Main',
  components: {
    CommonModalContainer,
    SystemModals,
    VIcon,
    UNavbar
  },
  data() {
    return {
      isMobileDevice: true
    };
  },
  fetch() {
    this.isMobileDevice = this.$device.isMobileOrTablet;
  },
  computed: {
    animation() {
      return {
        /**
         * Animation function
         *
         * Runs before animating, so you can take the initial height, width, color, etc
         * @param  {HTMLElement}  element  The notification element
         */
        enter(element) {
          const height = element.clientHeight;
          return {
            // animates from 0px to "height"
            height: [height, 0]

            // animates from 0 to random opacity (in range between 0.5 and 1)
            // opacity: [Math.random() * 0.5 + 0.5, 0]
          };
        },
        leave: {
          height: 0,
          opacity: 0
        }
      };
    }
  },
  mounted() {
    this.setIsMobile();
    if (this.$auth.loggedIn) {
      this.$statusService.subscribe();
    }
    if (window) {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }

    this.isMounted = true;
  },
  methods: {
    ...mapMutations(['setIsMobile']),
    onResize() {
      this.isMobileDevice = document.documentElement.clientWidth < 1140;
    }
  }
};
</script>

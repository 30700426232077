import { Api } from '~/api';

class StoryApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3/story';
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  async listActive() {
    const {
      data: {
        data: { stories, totalNumberOfStories }
      }
    } = await this.get('/list/active');
    return { stories, totalNumberOfStories };
  }

  async list() {
    const {
      data: {
        data: { stories }
      }
    } = await this.get('/list/all');
    return stories;
  }

  async carouselByStoryId(storyId) {
    const {
      data: { data }
    } = await this.get('/carousel', { params: { storyId } });
    return data;
  }

  addToFavourite(storyId) {
    return this.post('/favourite/add', { storyId });
  }

  removeFromFavourite(storyId) {
    return this.post('/favourite/remove', { storyId });
  }

  markCarouselItemAsViewed(carouselItemId) {
    return this.post('/carousel-item/mark-as-viewed', { carouselItemId });
  }
}

export default StoryApi;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('support-chat-message-container',{attrs:{"message":_vm.message}},[_c('div',[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"min-w-[48px] w-12 h-12 rounded-lg flex items-center justify-center mr-2 cursor-pointer bg-opacity-10",class:[
          _vm.isIncoming
            ? 'bg-v-ui-root-service-info-monochrome'
            : 'bg-v-ui-root-service-info-colored'
        ],on:{"click":_vm.downloadFile}},[_c('v-icon',{class:[
            _vm.isIncoming
              ? 'stroke-v-ui-icon-monochrome'
              : 'stroke-v-ui-icon-service-info-colored'
          ],attrs:{"name":"m/download"}})],1),_vm._v(" "),_c('div',{staticClass:"w-[calc(100%-60px)]"},[_c('div',{staticClass:"mb-1 text-sm leading-4 font-medium overflow-hidden overflow-ellipsis line-clamp-2 break-words"},[_vm._v("\n          "+_vm._s(_vm.message.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-v-ui-text-monochrome-27 text-xs leading-3"},[_vm._v("\n          "+_vm._s(_vm.message.size)+" "+_vm._s(_vm.message.extension)+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-end"},[_c('div',{staticClass:"text-xs leading-3 pt-[3px] text-v-ui-text-monochrome-27"},[_vm._v("\n        "+_vm._s(_vm.message.time)+"\n      ")]),_vm._v(" "),(_vm.message.isRead)?_c('v-icon',{staticClass:"stroke-v-ui-icon-service-info-colored ml-0.5",attrs:{"size":"s","name":"standard/check-read"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    id="textarea"
    ref="textarea"
    class="custom-scrollbar text-v-ui-text-monochrome-55 text-v-ui-text-monochrome resizable-textarea w-full box-border text-base text-gray-700 font-serif bg-background-base2 border border-gray-300 rounded-l max-h-40 min-h-10 max-w-[calc(100%-65px)] outline-none overflow-auto overflow-x-hidden pt-3 resize-none"
    :data-placeholder="placeholder"
    contenteditable="true"
    @input="onInput"
    @keydown.enter.prevent="sendMessage"
  ></div>
</template>

<script>
export default {
  name: 'UResizableTextarea',
  props: {
    protocol: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('common.resizable-textarea.default');
      }
    },
    regexpFilter: {
      type: [RegExp, null],
      default: null
    }
  },
  mounted() {
    this.$refs.textarea.value = '';

    const ele = document.getElementById('textarea');
    const placeholder = ele.getAttribute('data-placeholder');

    ele.innerHTML === '' && (ele.innerHTML = placeholder);

    console.log('ele', ele);

    ele.addEventListener('focus', (e) => {
      const value = e.target.innerHTML;
      value === placeholder && (e.target.innerHTML = '');
    });

    ele.addEventListener('blur', (e) => {
      const value = e.target.innerHTML;
      value === '' && (e.target.innerHTML = placeholder);
    });
  },
  methods: {
    sendMessage(e) {
      if (e.shiftKey) {
        const textarea = this.$refs.textarea;
        const browser = navigator.userAgent;
        const isFirefox = browser?.search(/Firefox/) > 0;

        let shift = '\n\n';
        if (isFirefox && textarea.innerText.includes('\n')) shift = '\n';

        const el = this.$refs.textarea;
        el.innerText += shift;

        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        return;
      }

      return this.$emit('messageSend', this.messageSend);
    },
    onInput(e) {
      if (this.regexpFilter) {
        e.target.innerText = e.target.textContent.replace(
          this.regexpFilter,
          ''
        );
        this.moveCaretToEnd(this.$refs.textarea);
      }
      this.$emit('inputText', e.target.innerText);
    },
    moveCaretToEnd(target) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(target);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      target.focus();
      range.detach();
    }
  }
};
</script>

<template>
  <div class="error-page">
    <div
      v-if="error.isAxiosError && errorCode"
      class="flex items-center justify-center"
    >
      <div
        class="flex flex-col justify-center items-center w-full w-80 bg-v-ui-root-monochrome-0 p-8 rounded-2xl"
      >
        <div class="text-v-ui-text-monochrome font-bold text-xl">
          {{ $t('domains.domains.layouts.error.title') }}
        </div>
        <div
          class="flex items-center justify-center bg-v-ui-root-monochrome-5 mt-4 px-2 h-8 w-full rounded"
        >
          <span class="text-sm font-medium"
            >{{ $t('domains.domains.layouts.error.code') }}:
            {{ errorCode }}</span
          >
        </div>
        <div
          class="text-base font-medium mt-4 text-v-ui-text-monochrome-55 text-center"
        >
          {{ $t('domains.domains.layouts.error.tg') }}
        </div>
        <v-button
          :label="$t('common.buttons.copy-and-send')"
          :right-icon="{ name: 'brand/telegram' }"
          @click="sendErrorToTelegram"
        />
      </div>
    </div>
    <div v-else class="error">
      <div class="error-body">
        <div class="error-title">
          {{ $t('domains.domains.layouts.error.ops') }}
        </div>
        <div class="error-p">
          {{ $t('domains.domains.layouts.error.something-get-wrong') }}...
        </div>
        <nuxt-link tag="div" to="/" class="error-button">{{
          $t('domains.domains.layouts.error.to-main-page')
        }}</nuxt-link>
        <div class="error-back" @click="$router.back()">
          {{ $t('domains.domains.layouts.error.back') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';
import TelegramIconSvg from '@/assets/icons/telegram.svg?inline';

export default {
  name: 'Error',
  // eslint-disable-next-line vue/no-unused-components
  components: { VButton, TelegramIconSvg },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      jsonError: null
    };
  },
  computed: {
    errorCode() {
      return (
        this.error?.isAxiosError && this.error?.response?.data?.data?.errorId
      );
    }
  },
  mounted() {
    console.log(this.error);
  },
  methods: {
    async sendErrorToTelegram() {
      await navigator.clipboard.writeText(this.errorCode);
      window.open('https://t.me/Doctis_error_bot', '_blank').focus();
    }
  }
};
</script>

<style lang="scss">
.error-page {
  .error {
    display: flex;
    margin: 40px;
    height: calc(100vh - 130px);
    background: var(--background-base2);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }
}

.send-error-btn {
  background: linear-gradient(180deg, #2aabee 0%, #229ed9 100%);
}

.error-body {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 486px;
  margin: 0 auto;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0 20px;
  }
}

.error-title {
  font-weight: bold;
  font-size: 35px;
  line-height: 44px;
  color: #616161;
  margin: 40px 0 20px 0;
  text-align: center;
}

.error-p {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #616161;
  text-align: center;
}

.error-button {
  width: 278px;
  margin: 20px auto;
  background: var(--background-base2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: var(--main-accen);
  cursor: pointer;
  @media (max-width: 576px) {
    width: 100%;
  }
}

.error-back {
  font-size: 16px;
  line-height: 16px;
  text-decoration: underline;
  color: #616161;
  text-align: center;
  cursor: pointer;
}
</style>

import {
  formirationGroupMessages,
  addWelcomeMessage,
  formirationMessage,
  formirationNewMessages
} from './support.helpers';
import { MEMBER_TYPE, MESSAGE_TYPE } from './support.const';
import SupportChatApi from './support.api';

class SupportChatService {
  constructor(app, client, hub, $config, $store) {
    this.app = app;
    this.api = new SupportChatApi(client, $config);
    this.hub = hub;
    this.$store = $store;
    this.groupsMessages = [];
    this.appealIds = [];
    this.handleMessage = null;

    this.onMessageLive();
  }

  /*
   * handleMessage - Получает отформатированные сообщения и добавляет их в последнее обращение
   */
  async init(handleMessage) {
    const groupsMessages = await this.getLastMessages();
    this.appealIds = await this.getAllAppealIds();

    /*
     * Если подписка на сообщение уже есть, то больше не подписываемся
     */
    if (!this.handleMessage) this.onMessage(handleMessage);

    return {
      groupsMessages
    };
  }

  async getLastMessages() {
    const {
      currentAppealMessages,
      previousAppealMessages
    } = await this.api.getLastMessages();
    /*
     * Приветственное сообщение всегда добавляем в конце при октрытии чата
     */
    this.groupsMessages = addWelcomeMessage(
      await formirationGroupMessages(
        [previousAppealMessages, currentAppealMessages],
        true
      )
    );

    return this.groupsMessages;
  }

  async getAllAppealIds() {
    return await this.api.getAllAppealIds();
  }

  async loadNextAppeal() {
    // const test = await this.api.getMessagesAppelaId();
  }

  /*
   * SOCKET MESSAGE
   */
  onMessage(cb) {
    this.handleMessage = true;
    this.hub.on('chat.message', async (message) => {
      if (message.params.chatAppealId) {
        const messages = this.groupsMessages;
        message = await formirationMessage(message, this.groupsMessages);
        messages[this.groupsMessages.length - 1].push(message);

        this.groupsMessages = formirationNewMessages(messages);
        cb(this.groupsMessages);
      }
    });
  }

  onMessageLive() {
    this.hub.on('chat.message', ({ member }) => {
      if (member?.memberType === MEMBER_TYPE.Admin) {
        this.$store.commit('setIsNewMessageSupportChat', true);
      }
    });
  }

  /*
   * SEND MESSAGE
   */
  async sendMessage(message) {
    await this.api.sendMessage(message);
  }

  /*
   * FILES
   */
  async sendFiles(files) {
    const filenames = await this.app.$fileService.sendFiles(files);
    for (const fileName of filenames) {
      this.sendMessage({
        payload: JSON.stringify({ fileNames: [fileName] }),
        messageType: MESSAGE_TYPE.FILE
      });
    }
  }
}

export default SupportChatService;

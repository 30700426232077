import SupportChatService from '@/services/support-chat/support.service';

export default function(appData, inject) {
  const { app, $axiosClient, $hubClient, $config, store } = appData;
  const client = $axiosClient.instance;

  const supportChatService = new SupportChatService(
    app,
    client,
    $hubClient,
    $config,
    store
  );
  inject('supportChatService', supportChatService);
}

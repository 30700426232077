<template>
  <div
    id="vc-support"
    class="md:absolute md:top-full left-0 md:mt-4 md:w-[400px] md:h-[600px] md:rounded-lg bg-v-ui-background-monochrome-0 drop-shadow-xl overflow-hidden h-full w-full fixed top-0 z-[100]"
  >
    <support-header @close="close" />
    <support-chat :groups-messages="groupsMessages" />
  </div>
</template>

<script>
import { scrollToBottom } from '~/services/support-chat/support.helpers';
import SupportHeader from '@/components/domains/support/ui/SupportHeader';
import SupportChat from '@/components/domains/support/ui/chat/SupportChat';

export default {
  name: 'VcSupport',
  components: {
    SupportHeader,
    SupportChat
  },
  data() {
    return {
      groupsMessages: []
    };
  },
  mounted() {
    this.init();
    this.checkScroll();
  },
  methods: {
    async init() {
      this.positionContent();

      const { groupsMessages } = await this.$supportChatService.init(
        this.handleMessage
      );
      this.groupsMessages = groupsMessages;
      this.$nextTick(() => scrollToBottom('support-chat'));
      this.checkScroll();
    },
    checkScroll() {
      const el = document.getElementById('support-chat');

      el.addEventListener('scroll', (e) => {
        const scroll = el.scrollTop;
        const height = el.scrollHeight - el.clientHeight;

        if (height - scroll >= height - 300) {
          console.log('Долистали до самого вверха. Получает еще чаты');
        }
      });
    },
    positionContent() {
      /*
       * Позиция окна на десктоп версии
       */
      const mobileSize = 990;

      const chat = document.getElementById('vc-support');
      const posChat = chat.getBoundingClientRect().right;
      const innerWidth = window.innerWidth;

      if (posChat > innerWidth && innerWidth > mobileSize) {
        chat.style.left = `${innerWidth - posChat - 30}px`;
      }
    },
    handleMessage(messages) {
      this.groupsMessages = messages;
      this.$nextTick(() => scrollToBottom('support-chat'));
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <nav
    ref="unavbar"
    class="sticky top-0 h-14 z-50 bg-v-ui-root-monochrome-0 lg:h-[72px] border-b-2 border-v-ui-icon-monochrome-0"
  >
    <div class="container  h-full flex justify-between items-center">
      <div class="h-7 lg:h-9 lg:mb-4">
        <u-link class="block lg:w-40" :link="{ path: '/' }" @click="closeMenu">
          <nuxt-img
            :src="logoPath"
            loading="lazy"
            format="webp"
            sizes="lg:120 xl:160"
            quality="100"
            alt="logo"
            class="w-full h-full"
            fit="fill"
          />
        </u-link>
      </div>

      <div
        v-show="isLinksVisible"
        class="fixed z-50 inset-0 mt-14 bg-v-ui-root-monochrome-0 lg:!block lg:static lg:mt-0 lg:flex-1"
      >
        <ul
          class="flex flex-col h-full lg:flex-row lg:items-center lg:container"
          @click="onClickMenu"
        >
          <li
            v-for="link in links"
            v-show="link.enabled"
            :key="link.title"
            class="relative border-l-4 border-l-v-ui-root-monochrome-0 px-3 lg:border-none lg:ml-4 lg:py-2.5 lg:px-4 lg:bg-v-ui-root-monochrome-0"
            :class="{
              'bg-[#EFEBFD] !border-l-v-ui-root-main':
                link.inner && link.link.name === currentRouteName
            }"
          >
            <u-dropdown
              v-if="link.dropdown"
              :label="link.title"
              btn-type="ghost"
              :items="link.items"
              button-class="text-xl font-medium md:text-base md:px-3 !px-0 md:py-0 py-2.5"
            >
              <template #item="{ item }">
                <u-link
                  :link="createLink(item)"
                  :inner="item.inner"
                  class="flex items-center relative h-9 border-l-4 border-l-v-ui-root-monochrome-0 -left-4
                    px-3 text-base text-v-ui-text-monochrome-55 font-medium lg:border-none lg:text-v-ui-root-main
                    lg:bg-v-ui-root-monochrome-0 lg:left-0 lg:px-0"
                  :class="{
                    'bg-[#EFEBFD] !border-l-v-ui-root-main !text-v-ui-root-main':
                      item.inner && item.link.name === currentRouteName
                  }"
                >
                  {{ item.title }}
                </u-link>
              </template>
            </u-dropdown>
            <u-link
              v-else
              :ref="link.ref"
              :inner="link.inner"
              :link="createLink(link)"
              class="h-14 flex items-center justify-between w-full text-v-ui-root-main text-xl font-medium lg:h-auto lg:text-base"
            >
              <span> {{ link.title }}</span>
            </u-link>
          </li>

          <li class="ml-auto mt-auto lg:mt-0 w-full lg:w-auto">
            <div v-if="loggedIn" class="relative">
              <u-dropdown
                :label="$auth.user.firstName"
                class="hidden lg:block"
                :items="loggedInLinks"
              >
                <template #item="{ item }">
                  <u-link
                    v-if="!item.isButton"
                    :link="createLink(item)"
                    :inner="item.inner"
                    class="flex items-center text-sm font-medium text-v-ui-root-main h-9 "
                  >
                    {{ item.title }}
                  </u-link>
                  <span
                    v-else
                    class="flex items-center text-sm font-medium text-v-ui-root-main h-9 cursor-pointer"
                    @click="item.handler"
                  >
                    {{ item.title }}
                  </span>
                </template>
              </u-dropdown>
              <div
                class="flex flex-col pb-6 bg-v-ui-root-monochrome-5 lg:hidden"
              >
                <p
                  v-if="isFullAuthorized"
                  class="flex items-center pl-4 text-xl font-medium h-14"
                >
                  {{ $auth.user.firstName }}
                </p>

                <template v-for="(item, idx) in loggedInLinks">
                  <u-link
                    v-if="!item.isButton"
                    :key="idx"
                    :link="createLink(item)"
                    :inner="item.inner"
                    class="flex items-center h-11 pl-3 border-l-4 border-l-v-ui-root-monochrome-5 text-base text-v-ui-text-monochrome-55 font-medium lg:text-v-ui-root-main lg:border-none"
                    :class="{
                      'bg-[#EFEBFD] !border-l-v-ui-root-main !text-v-ui-root-main':
                        item.link.name === currentRouteName
                    }"
                  >
                    {{ item.title }}
                  </u-link>
                  <span
                    v-else
                    :key="idx"
                    class="flex items-center h-11 pl-3 border-l-4 border-l-v-ui-root-monochrome-5 text-base text-v-ui-text-monochrome-55 font-medium cursor-pointer lg:text-v-ui-root-main lg:border-none"
                    @click="item.handler"
                  >
                    {{ item.title }}
                  </span>
                </template>
              </div>
            </div>
            <div v-else class="md:px-0 px-4 md:pb-0 pb-16">
              <v-button
                type="light"
                rounded
                size="m"
                :label="$t('common.buttons.enter')"
                class="w-full"
                @click="toAuth"
              >
              </v-button>
            </div>
          </li>
        </ul>
      </div>

      <a
        class="hidden whitespace-nowrap ml-5 text-v-ui-text-monochrome text-xl font-medium lg:block"
        href="tel:88005000299"
      >
        8 800 500 0299
      </a>

      <div class="flex items-center">
        <div v-if="isChatAppealEnabled" class="relative ml-7">
          <div class="relative cursor-pointer" @click="toggleSupport">
            <v-icon name="m/dialog" class="stroke-v-ui-root-main" />
            <div
              v-if="isNewMessageSupportChat && !isSupport"
              class="absolute top-[-4px] right-[-4px] w-3 h-3 bg-v-ui-root-accent border-[3px] border-v-ui-icon-monochrome-0 rounded-full"
            ></div>
          </div>
          <VcSupport v-if="isSupport" @close="toggleSupport" />
        </div>

        <v-icon
          class="ml-4 text-v-ui-icon-main lg:hidden"
          :name="isLinksVisible ? 'm/cross' : 'm/menu'"
          @click.native="toggleMenu"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { VIcon, VButton } from '@doctis.front/doctis.designsystem';
import ULink from '@/components/ui/atoms/link/ULink';
import UDropdown from '@/components/ui/atoms/dropdown/UDropdown';
import VcSupport from '@/components/domains/support/VcSupport';
import { PATH_TO_LOGO } from '@/consts';
import { MODALS } from '@/services/core/ui/const';

export default {
  name: 'UNavbar',
  components: {
    UDropdown,
    ULink,
    VcSupport,

    VButton,
    VIcon
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isSupport: false,
      isLinksVisible: false,

      loggedInLinks: [
        // {
        //   title: this.$t('navbar.logged-links.dashboard'),
        //   inner: true,
        //   link: { path: '/dashboard' }
        // },
        {
          title: this.$t('navbar.logged-links.account'),
          inner: true,
          link: { path: '/account/settings' }
        },
        {
          title: this.$t('navbar.logged-links.future'),
          inner: true,
          link: { path: '/events/future' }
        },
        {
          title: this.$t('navbar.logged-links.monitoring'),
          inner: true,
          link: { path: '/monitoring/remote-monitoring' }
        },
        {
          title: this.$t('navbar.logged-links.logout'),
          handler: this.logout,
          isButton: true
        }
      ]
    };
  },
  computed: {
    ...mapState(['isMobile', 'isNewMessageSupportChat']),
    ...mapGetters('modules/auth', ['isFullAuthorized']),
    ...mapState('modules/profile', ['settings']),
    currentRouteName() {
      return this.$route.name;
    },
    logoPath() {
      const logoLocale = this.$config.APP_INTERNATIONAL_STAND ? 'EN' : 'RU';
      return PATH_TO_LOGO[logoLocale];
    },
    isNavbarLinkDisabled() {
      return !this.$config.APP_INTERNATIONAL_STAND;
    },
    links() {
      const links = [
        {
          title: this.$t('common.buttons.sign-up'),
          link: { path: '/dashboard' },
          inner: true,
          enabled: true
        },
        {
          title: this.$t('navbar.analyzes'),
          link: { path: '/analysis' },
          inner: true,
          enabled: this.isNavbarLinkDisabled
        }
      ];
      if (!this.$config.APP_INTERNATIONAL_STAND) {
        links.push({
          title: this.$t('navbar.products'),
          dropdown: true,
          enabled: true,
          items: [
            // {
            //   title: this.$t('navbar.women-health'),
            //   link: {
            //     path:
            //       'https://www.doctis.ru/landings/check-up-dlya-zhenshchin-obsledovanie'
            //   }
            // },
            // {
            //   title: 'Фетальный монитор',
            //   inner: true,
            //   link: { path: '/fetalnyj-monitor' }
            // },
            {
              title: this.$t('navbar.telemedicine-centers'),
              inner: true,
              link: { path: '/telemed-centers' }
            },
            {
              title: this.$t('navbar.about-telemedicine'),
              inner: true,
              link: { path: '/promo/consultations' }
            }
            // {
            //   title: 'Бронхиальная астма',
            //   link: { path: 'https://astmarisk.doctis.ru' }
            // }
          ]
        });
      }
      return links;
    },
    isChatAppealEnabled() {
      return this.settings?.isChatAppealEnabled && this.loggedIn;
    }
  },
  mounted() {
    /** Добавление рамки при скроле страницы */
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapMutations(['setIsNewMessageSupportChat']),
    toggleSupport() {
      this.setIsNewMessageSupportChat(false);
      this.isSupport = !this.isSupport;
    },
    toAuth() {
      this.$authService.startAuthorizationFlow();
    },
    onClickMenu() {
      this.closeMenu();
    },
    toggleMenu() {
      if (this.isLinksVisible) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },
    openMenu() {
      this.isLinksVisible = true;
    },
    closeMenu() {
      this.isLinksVisible = false;
    },
    createLink(link) {
      return link.inner ? link.link.path : link.link;
    },
    logout() {
      this.$uiService.showModal(MODALS.AUTH_LOGOUT_DIALOG);
    },
    handleScroll() {
      if (this.$route.name !== 'account-patient-id') {
        if (window.scrollY > 0) {
          this.$refs.unavbar.classList.add('border-v-ui-line-monochrome-10');
        } else {
          this.$refs.unavbar.classList.remove('border-v-ui-line-monochrome-10');
        }
      }
    }
  }
};
</script>

<template>
  <div class="flex items-end pl-4 pr-2.5 my-2" :class="[messageClass]">
    <u-avatar-support-chat v-if="message.incoming" class="mr-2.5" />

    <div
      class="relative flex items-end max-w-[80%] cursor-pointer"
      :class="[messageClass]"
      @click="downloadFile"
    >
      <img :src="message.src" class="rounded-xl min-w-[100px]" />

      <div
        class="absolute bottom-1 right-1 bg-v-ui-overlay-monochrome-40 bg-opacity-60 text-v-ui-text-monochrome-0 text-xs rounded-full px-1.5 flex items-center justify-center h-5"
      >
        <div>{{ message.time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@/services/files/files.helpers';
import UAvatarSupportChat from '@/components/ui/atoms/avatar/UAvatarSupportChat';

export default {
  name: 'SupportChatMessageImage',
  components: {
    UAvatarSupportChat
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    messageClass() {
      return this.message.incoming ? '' : 'justify-end';
    }
  },
  methods: {
    downloadFile() {
      const { blob, title } = this.message;
      downloadFile(blob, title);
    }
  }
};
</script>

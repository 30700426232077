import { MODALS } from '@/services/core/ui/const';

export const state = () => ({
  isMobile: undefined,
  isMobileSM: undefined,

  isMobileDevice: null,

  ssrAxiosErrors: [],

  isNewMessageSupportChat: false
});

export const actions = {
  nuxtClientInit({ state, dispatch }) {
    if (state.ssrAxiosErrors.length) {
      state.ssrAxiosErrors.forEach((error) => {
        this.$uiService.showModal(MODALS.REQUEST_ERROR_DIALOG, {
          props: { errorId: error }
        });
      });
      dispatch('resetAxiosErrors');
    }
  },
  async nuxtServerInit({ rootState, dispatch, commit }) {
    commit('setIsMobileDevice', this.$device.isMobileOrTablet);
    if (rootState.auth.loggedIn) {
      await dispatch('userInit');
    }
  },
  async initAfterAuth({ dispatch }) {
    this.$amplitude.logEvent('auth_finished', { result: true });
    if (process.client) {
      await dispatch('userInit');
      await this.$hubClient.init();
      await this.$dispatcherClient.init();
    }
  },
  async userInit({ dispatch }) {
    try {
      await dispatch('modules/patient/fetchProfile');
      await dispatch('modules/patient/fetchFamilyProfiles');
      await dispatch('modules/profile/getProfile');
      await dispatch('modules/profile/getUserProfiles');
      await dispatch('modules/profile/getSetting');
    } catch (error) {
      await this.$authService.logout();
      console.log(error);
    }

    // await dispatch('modules/appointment/setAppointmentAfterAuth');
  },
  addAxiosError({ commit }, error) {
    commit('addAxiosError', error);
  },
  resetAxiosErrors({ commit }) {
    commit('resetAxiosErrors');
  }
};

export const mutations = {
  setIsMobile(state) {
    const vh = window.innerHeight * 0.01;
    const width = window.innerWidth;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    state.isMobile = width < 992;
    state.isMobileSM = width < 576;
  },
  addAxiosError(state, error) {
    state.ssrAxiosErrors.push(error);
  },
  resetAxiosErrors(state) {
    state.ssrAxiosErrors = [];
  },
  setIsNewMessageSupportChat(state, value) {
    state.isNewMessageSupportChat = value;
  },
  setIsMobileDevice(state, isMobile) {
    state.isMobileDevice = isMobile;
  }
};

export const strict = false;

import { isSameDay, parseISO, startOfDay } from '@evd3v/date-fns';
import ChatApi from './chat.api';

const MESSAGE_TYPE = 'chat.message';

class ChatService {
  constructor(client, hub, $config) {
    this.api = new ChatApi(client, $config);
    this.hub = hub;
  }

  static CHAT_MESSAGE_TYPE = {
    TEXT: 'Text',
    CALL: 'Call',
    FILE: 'File',
    TIMESTAMP: 'Timestamp'
  };

  static CALL_TYPE = {
    AUDIO: 'Audio',
    VIDEO: 'Video',
    SIP: 'Sip'
  };

  static CALL_STATUS = {
    STARTED: 'Started',
    NOT_ANSWERED: 'NotAnswered',
    DONE: 'Done'
  };

  static MEMBER_TYPE = {
    DOCTOR: 'Doctor',
    PATIENT: 'Patient'
  };

  /**
   * @param {string | number} consultationId
   * @returns {Promise<Array<ChatMessageModel>>}
   */
  async getList(consultationId) {
    const {
      data: { data }
    } = await this.api.list(consultationId);

    return data;
  }

  /**
   * @param {ChatMessageSendDTO} message
   * @returns {Promise<ChatMessageModel>}
   */
  sendMessage(message) {
    return this.api.send(message);
  }

  addTimestamps(messages) {
    const result = [];

    messages.forEach((current, index, array) => {
      const { createdAt } = current;

      if (index === 0) {
        const timestamp = this.createTimestamp(createdAt);
        result.push(timestamp);
      } else {
        const previous = array[index - 1];

        const isInSameDay = isSameDay(
          parseISO(previous.createdAt),
          parseISO(createdAt)
        );

        if (!isInSameDay) {
          const timestamp = this.createTimestamp(createdAt);
          result.push(timestamp);
        }
      }

      result.push(current);
    });

    return result;
  }

  createTimestamp(date) {
    return {
      messageType: ChatService.CHAT_MESSAGE_TYPE.TIMESTAMP,
      payload: JSON.stringify({
        timestamp: startOfDay(parseISO(date)).toISOString()
      })
    };
  }

  /**
   * @param {string | number} consultationId
   * @param {ChatMessageCallback} cb
   */
  onMessage(consultationId, cb) {
    this.hub.on(MESSAGE_TYPE, (message) => {
      if (consultationId === message.params.consultationId) {
        cb(message);
      }
    });
  }
}

export default ChatService;

import {
  format,
  isSameDay,
  parseISO,
  isToday,
  isYesterday
} from '@evd3v/date-fns';
import ru from '@evd3v/date-fns/locale/ru';
import { MEMBER_TYPE, MESSAGE_TYPE } from './support.const';

export const scrollToBottom = (id) => {
  const el = document.getElementById(id);
  if (el) {
    setTimeout(() => {
      el.scrollTo({
        top: el.scrollHeight,
        behavior: 'smooth'
      });
    }, 0);
  }
};

export const formirationGroupMessages = async (messages) => {
  const formattedGroups = await Promise.all(
    messages.map(async (group) => {
      const formattedMessages = await Promise.all(
        group
          .map(async (message) => {
            return await formirationMessage(message);
          })
          .map(async (message, index, list) => {
            /*
             * Определение последнего сообщения пользователя(Оператор|Пользователь)
             */
            let isLast = false;
            message = await message;
            const nextMessage = await list[index + 1];

            if (
              message?.incoming === nextMessage?.incoming &&
              nextMessage?.messageType !== MESSAGE_TYPE.SYSTEM
            )
              isLast = true;
            return {
              ...message,
              isLast
            };
          })
      );

      return addTimestamps(
        formattedMessages.map((message, index, list) => {
          /*
           * Определение последнего сообщения пользователя(Оператор|Пользователь)
           */
          let isLast = false;
          const nextMessage = list[index + 1];

          if (
            message?.incoming === nextMessage?.incoming &&
            nextMessage?.messageType !== MESSAGE_TYPE.SYSTEM
          )
            isLast = true;
          return {
            ...message,
            isLast
          };
        })
      );
    })
  );

  return formattedGroups;
};

export const formirationMessage = async (message) => {
  const messageType = !message.member
    ? MESSAGE_TYPE.SYSTEM
    : message.messageType;

  let params = {};

  switch (messageType) {
    case MESSAGE_TYPE.FILE:
      params = await getFileParams(message);
      break;
  }

  const incoming = MEMBER_TYPE[message?.member?.memberType] === 'Admin';
  return {
    time: format(parseISO(message.createdAt), 'HH:mm'),
    name: getUserSupportName(message),
    avatar: message.member?.personData?.avatarUrl,
    createdAt: message.createdAt,
    incoming,
    messageType,
    text: message.text,
    ...params
  };
};

export const formirationNewMessages = (messages) => {
  const currentAppeal = messages[1];
  const prevMessage = currentAppeal[currentAppeal.length - 2];
  const lastMessage = currentAppeal[currentAppeal.length - 1];

  if (prevMessage.incoming === lastMessage.incoming && !prevMessage.isLast) {
    prevMessage.isLast = true;
    prevMessage.text = `${prevMessage.text} `;
    lastMessage.isLast = false;
  } else {
    lastMessage.isLast = false;
  }

  messages[1][currentAppeal.length - 2] = prevMessage;
  messages[1][currentAppeal.length - 1] = lastMessage;

  return messages;
};

export const addTimestamps = (messages) => {
  const result = [];
  messages.forEach((current, index, array) => {
    const { createdAt } = current;

    if (index === 0) {
      const timestamp = createTimestamp(createdAt);
      result.push(timestamp);
    } else {
      const previous = array[index - 1];

      const isInSameDay = isSameDay(
        parseISO(previous.createdAt),
        parseISO(createdAt)
      );

      if (!isInSameDay) {
        const timestamp = createTimestamp(createdAt);
        result.push(timestamp);
      }
    }

    result.push(current);
  });

  return result;
};

export const createTimestamp = (date) => {
  return {
    messageType: MESSAGE_TYPE.TIMESTAMP,
    payload: formatedDateTimestamp(date)
  };
};

export const formatedDateTimestamp = (date) => {
  date = parseISO(date);
  if (isToday(date)) {
    return 'Сегодня';
  } else if (isYesterday(date)) {
    return 'Вчера';
  } else {
    return format(date, 'dd MMM', { locale: ru });
  }
};

export const getUserSupportName = (message) => {
  if (MEMBER_TYPE[message?.member?.memberType] === 'Admin') {
    return message.member?.personData?.firstName;
  }

  return null;
};

export const getFileParams = async (file) => {
  let fileInformation = JSON.parse(file.payload).filesInformation;
  if (fileInformation?.length) {
    fileInformation = fileInformation[0];
    const res = await window.$nuxt.$fileService.getFileByFilename(
      fileInformation
    );
    return {
      messageType: fileInformation.mimeType.includes('image')
        ? MESSAGE_TYPE.IMAGE
        : MESSAGE_TYPE.FILE,
      ...res
    };
  }

  return null;
};

export const addWelcomeMessage = (messages) => {
  messages[messages.length - 1].push(
    ...[
      { ...createTimestamp(new Date().toISOString()) },
      {
        createdAt: new Date().toISOString(),
        messageType: MESSAGE_TYPE.SYSTEM,
        text: 'Если у вас есть вопрос, напишите нам и мы ответим'
      }
    ]
  );
  return messages;
};
